import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  initialize() {
    // Скрыть и показать список дизайнеров
    // при открытии модалки (чтобы убрать мирцание)
    this.toggleCards();
  }

  toggleCards() {
    const voteModals = document.querySelectorAll('.vote-modal');
    const cards = document.querySelectorAll('.card-body');

    voteModals.forEach(function (modal) {
      modal.addEventListener('show.bs.modal', function (event) {
        cards.forEach(function (card) {
          card.classList.add('no-bg');
        });
      });

      modal.addEventListener('hidden.bs.modal', function (event) {
        cards.forEach(function (card) {
          card.classList.remove('no-bg');
        });
      });
    });
  }
}

import { Controller } from "@hotwired/stimulus"
import LazyLoad from "vanilla-lazyload";
import * as bootstrap from "bootstrap"

export default class extends Controller {
  initialize() {
    // Инициализация ленивой загрузки изображений
    this.initLazyLoad();
    
    // Инициализация карусели при нажатии открытии модального окна
    this.initCarousels();

    // Скрыть и показать список дизайнеров
    // при открытии модалки (чтобы убрать мирцание)
    this.toggleCards();
  }

  toggleCards() {
    const voteModals = document.querySelectorAll('.vote-modal');
    const cards = document.querySelectorAll('.card-body');

    voteModals.forEach(function (modal) {
      modal.addEventListener('show.bs.modal', function (event) {
        cards.forEach(function (card) {
          card.classList.add('no-bg');
        });
      });

      modal.addEventListener('hidden.bs.modal', function (event) {
        cards.forEach(function (card) {
          card.classList.remove('no-bg');
        });
      });
    });
  }

  initLazyLoad() {
    let lazyLoadInstance = new LazyLoad();
    lazyLoadInstance.update();
  }

  initCarousels() {
    const voteModals = document.querySelectorAll('.vote-modal');
    const self = this;

    voteModals.forEach(function (modal) {
      modal.addEventListener('show.bs.modal', function (event) {
        const carouselId = event.target.dataset.carousel;
        self.initCarousel(carouselId);
      });
    });
  }

  initCarousel(carouselId) {
    const self = this;
    const carousel = document.getElementById(carouselId);

    if(carousel.classList.contains('init')) {
      return;
    }

    const carouselInner = carousel.querySelector('.carousel-inner');
    const carouselIndicators = carousel.querySelector('.carousel-indicators');

    const claimId = carousel.dataset.claim;

    const imagesPath = carousel.dataset.images;
    const imagesRootPath = carousel.dataset.imagesRoot;

    let index = 0;

    self.getCarouselImages(imagesPath, imagesRootPath, function (images) {
      images.forEach(function (image) {
        let carouselItem = document.createElement('div');
        carouselItem.className = (index === 0) ? 'carousel-item active' : 'carousel-item';
        carouselItem.innerHTML = `
              <img src="${image}"
              data-src="${image}"
              class="designer-img d-block lazy" alt="image">
            `
        carouselInner.appendChild(carouselItem);

        if(images.length > 1) {
          let indicator = document.createElement('button');
          indicator.className = (index === 0) ? 'active' : '';
          indicator.dataset.bsTarget = `#vote-carousel-${claimId}`;
          indicator.dataset.bsSlideTo = index;

          if(index === 0) {
            indicator.setAttribute('aria-current', 'true');
          }

          carouselIndicators.appendChild(indicator);
        }

        index += 1;
      });
    });

    new bootstrap.Carousel(carousel, {
      interval: 150000,
      wrap: false,
      ride: true
    });

    carousel.classList.add('init');
  }

  getCarouselImages(imagesPath, rootPath, callback) {
    let images = [];
    let parser = new DOMParser();
   
    this.getHtml(imagesPath, function (err, data) {
      let doc = parser.parseFromString(data, 'text/html');
      let links = Array.from(doc.querySelectorAll('a')).map(a => a.getAttribute('href'));

      if (links && links.length > 1) {
        for (let i = 1; i < links.length; i++) {
          let link = links[i];
          images.push(`${rootPath}${link}`);
        }

        callback(images);
      }
    });

    return images;
  }

  getHtml(url, callback) {
    const xhr = new XMLHttpRequest();

    xhr.open('GET', url, true);
    xhr.responseType = 'html';
    xhr.onload = function() {
      const status = xhr.status;
      if (status === 200) {
        callback(null, xhr.response);
      }
    };
    xhr.send();
  }

  getJson(url, callback) {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);
    xhr.responseType = 'json';
    xhr.onload = function() {
      const status = xhr.status;
      if (status === 200) {
        callback(null, xhr.response);
      } else {
        callback(status, xhr.response);
      }
    };
    xhr.send();
  };
}

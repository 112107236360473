import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs";

// Connects to data-controller="votes"
export default class extends Controller {
  connect() {}

  create(event) {
    const target = event.target;
    const rating = target.dataset.rating;
    const brand = target.dataset.brand;
    const category = target.dataset.category;
    const claim = target.dataset.claim;

    Rails.ajax({
      type: "post",
      url: '/votes',
      data: new URLSearchParams({ rating: rating, brand: brand, category: category }).toString(),
      dataType: 'script',
      success: function() {
        const collection = target.parentElement.children;

        for (let i = 0; i < collection.length; i++) {
          collection[i].classList.remove("chosen");
        }

        target.classList.add('chosen');

        let voteLabel = document.getElementById(claim);
        voteLabel.textContent = rating;
        voteLabel.classList.remove('d-none');
      },
    })
  }
}
